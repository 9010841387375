#welcome{
    background-color: #dd7230;
}

.text-container{
    width: 355px;
}

.text-container h2{
    overflow: hidden;
    width: 355px;
    border-right: .15em solid #2E1F27;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation:
        typing 3.5s steps(40, end),
        blink-caret .75s step-end infinite;
}

@keyframes typing {
    from {width: 0}
    to {width: 100%}
}

@keyframes blink-caret{
    from, to {border-color: transparent}
    50% {border-color: orange;}
}