#social{
    background-color: #B3AF8F;
}

#social-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15%;
    margin-right: 15%;
}

.icon-container{
    display: flex;
    width: 100%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    justify-content: space-between;
    align-content: center;
  }
  
  .icon{
   height: 100%;
   width: 100px; 
  }
