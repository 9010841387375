#education{
    background-color: #66999B;
}

#education-container{
    width: 100%
}

.school{
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.school h1{
    font-size: 1.7rem;
}

.school h1{
    font-size: 1.7rem;
}